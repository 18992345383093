<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-04-27 14:46:50
 * @LastEditors: pj
 * @LastEditTime: 2023-04-27 14:46:52
-->
<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-03-23 16:20:01
 * @LastEditors: pj
 * @LastEditTime: 2023-03-23 16:20:03
-->
<template>
  <el-cascader
    v-model="selectValue"
    :options="options"
    :props="defaultProps"
    :disabled="disabled"
    @change="handleChange"
  ></el-cascader>
</template>

<script>
export default {
  name: 'glCascader',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    one: {
      type: String,
      default: '',
    },
    two: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      defaultProps: {
        label: 'categoryName',
        value: 'categoryId',
        children: 'children',
        expandTrigger: 'hover',
      },
      selectValue: this.value || [],
    }
  },
  computed: {
    options() {
      return this.list
    },
  },
  watch: {
    one: {
      handler: function (newVal) {
        // if(newVal && this.disabled){
        this.selectValue = [this.one, this.two]
        // }
      },
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('input', value)
      this.$emit('change', value)

      if (this.one !== null) this.$emit('update:one', value[0])
      if (this.two !== null) this.$emit('update:two', value[1])
    },
  },
}
</script>

<style></style>
