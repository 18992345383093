<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-04-27 10:08:07
 * @LastEditors: pj
 * @LastEditTime: 2023-04-28 16:57:39
-->
<template>
  <div>
    <gl-title title="团购订单"></gl-title>
    <div>
      <gl-page-header>
        <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
          <!-- <el-form-item prop="firstCategory" label="分类">
            <selectCascader
              :one.sync="mFormData.firstCategory"
              :two.sync="mFormData.secondCategory"
              :list="options"
            ></selectCascader>
          </el-form-item> -->

          <el-form-item prop="keyword" label="输入产品名">
            <el-input
              v-model="mFormData.keyword"
              style="width: 900px"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="mReload">
              搜索
            </el-button>
            <el-button icon="el-icon-refresh" @click="mResetForm('listForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </gl-page-header>

      <gl-card v-loading="mLoading">
        <el-table :data="mTableData" style="width: 100%">
          <el-table-column
            type="index"
            :index="mIndexMethod"
            label="序号"
            align="center"
            min-width="30"
          ></el-table-column>
          <el-table-column
            prop="itemName"
            label="商品"
            align="center"
            width="350"
          ></el-table-column>

          <el-table-column
            prop="batchPrice"
            label="团购价格"
            align="center"
            width="350"
          ></el-table-column>
          <el-table-column
            prop="orderNum"
            label="申请数量"
            align="center"
            width="350"
          ></el-table-column>
          <el-table-column
            prop="batchQuantity"
            label="合计金额"
            align="center"
            width="350"
          ></el-table-column>

          <el-table-column prop="name" label="操作" width="130px" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="
                  $router.push({
                    path: 'groupPlatformInfo',
                    query: { itemId: scope.row.itemId },
                  })
                "
              >
                查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <gl-pagination
          :total="mTotal"
          :current-page="mPageNum"
          @currentChange="mHandleCurrentChange"
          @sizeChange="mHandleSizeChange"
        >
        </gl-pagination>
      </gl-card>
      <statusDialog :itemId="mItemId" :visible.sync="mUpdateVisible" />
      <detailDialog :itemId="mItemId" :visible.sync="mPreviewVisible" />
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import ListMixin from '@/mixins/list.mixin.js'
import { getGroupBuyingItem } from '@/api/coox/orderGoods.js'
import { getCategoryTree } from '@/api/coox/shop.js'
import selectCascader from './selectCascader.vue'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: { selectCascader },
  props: {},
  mixins: [ListMixin],
  data() {
    // 这里存放数据
    return {
      options: [],
      formData: {},
    }
  },
  // 计算属性类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  async mounted() {
    const result = await getCategoryTree()
    this.options = result.data
    this.mGetListFun = getGroupBuyingItem
    this.mGetList()
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped></style>
